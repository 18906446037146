import React from "react";
import Layout from "../Layout";
import { Flex, Box, Container, Heading, Link, Icon } from "@chakra-ui/react";
import { useLocale } from "../../../locales";
import { BsWhatsapp, BsEnvelope, BsTelephone } from "react-icons/bs";

const ALL_SUPPORT_METHODS = ["email", "whatsapp", "phone"];

function whatsappUrl(phone) {
  const formattedPhone = phone.replace(/[^0-9]/gi, "");
  return `https://wa.me/${formattedPhone}`;
}

export default function SupportPage() {
  const { tr, trExists } = useLocale();

  const renderSupportMethod = (method) => {
    const trString = `support.${method}`;
    if (!trExists(trString)) return;
    const value = tr(trString);
    if (!value) return;

    let icon = <></>;
    let href = "";
    if (method === "email") {
      href = `mailto:${value}`;
      icon = <Icon as={BsEnvelope} />;
    } else if (method === "whatsapp") {
      icon = <Icon as={BsWhatsapp} />;
      href = whatsappUrl(value);
    } else if (method === "phone") {
      icon = <Icon as={BsTelephone} />;
      href = `tel:${value}`;
    }
    return (
      <Box p={5} shadow="md" borderWidth="1px" marginY={4}>
        <Flex gap={2} alignItems="center" marginBottom={4}>
          {icon}
          <Heading fontSize="lg" margin={0}>
            {tr(`labels.${method}`)}
          </Heading>
        </Flex>
        <Box>
          {href ? (
            <Link href={href} isExternal color="palette.link">
              {value}
            </Link>
          ) : (
            value
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Heading size="3xl" color="palette.blue.1" mb={8}>
            {tr("pages.support.title")}
          </Heading>
          {ALL_SUPPORT_METHODS.map((method) => {
            const trString = `support.${method}`;
            if (!trExists(trString)) {
              return <></>;
            }
            return <div key={method}>{renderSupportMethod(method)}</div>;
          })}
        </Box>
      </Container>
    </Layout>
  );
}
