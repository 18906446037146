import React from "react";
import Layout from "../Layout";
import { Box, Container, Image, Center } from "@chakra-ui/react";
import { useLocale } from "../../../locales";

export default function HostAnATM() {
  const { lang, region } = useLocale();
  const flyerUrl = `${process.env.PUBLIC_URL}/images/host-an-atm/flyer-${region.code}-${lang}.svg`;
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box>
          <Center>
            <Image
              src={flyerUrl}
              alt="Host Bitcoin ATM flyer"
              maxW="100%"
              width="600px"
            />
          </Center>
        </Box>
      </Container>
    </Layout>
  );
}
