import { isDev } from "../helpers/env";

export const EN = "en";
export const FR = "fr";
export const TR = "tr";
export const ES = "es";
export const UNKNOWN = "unknown";

export const GLOBAL = {
  code: "global",
  baseUrl: "coineka.com",
  langs: [EN],
};
export const CANADA = {
  code: "ca",
  baseUrl: "coineka.ca",
  langs: [EN, FR],
};
export const TURKEY = {
  code: "tr",
  baseUrl: "coineka.com.tr",
  langs: [TR, EN],
};
export const DOMREP = {
  code: "do",
  baseUrl: "coineka.com.do",
  langs: [ES, EN, FR],
};
export const REGIONS = [GLOBAL, TURKEY, DOMREP];
// HACK: overwrite baseUrls in localhost development to avoid setting hosts file:
if (isDev()) {
  REGIONS.forEach((region) => {
    const suffix = region.code;
    region.baseUrl = `localhost:3000/${suffix}`;
  });
}

export const DEFAULT_REGION = GLOBAL;

export const GLOBAL_EN = { region: GLOBAL, lang: EN };
export const GLOBAL_UNKNOWN = { region: GLOBAL, lang: UNKNOWN };
export const CANADA_EN = { region: CANADA, lang: EN };
export const CANADA_FR = { region: CANADA, lang: FR };
export const TURKEY_TR = { region: TURKEY, lang: TR };
export const TURKEY_EN = { region: TURKEY, lang: EN };
export const DOMREP_ES = { region: DOMREP, lang: ES };
export const DOMREP_EN = { region: DOMREP, lang: EN };

export const DEFAULT_LOCALE = GLOBAL_EN;
