import React from "react";

import { Button, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { Link as RLink } from "react-router-dom";
import { useLocale } from "locales";

function modelsUrl(path) {
  return `${process.env.PUBLIC_URL}/images/3d-models/${path}`;
}
function atmsUrl(path) {
  return `${process.env.PUBLIC_URL}/images/atms/${path}`;
}

export default function HeroSection() {
  const { tr, url, region } = useLocale();
  // const heroImageUrl = `${process.env.PUBLIC_URL}/images/batms.webp`;
  let heroImageUrl = modelsUrl("angled-no-bank-logos.png");
  if (region.code === "tr") {
    heroImageUrl = modelsUrl("angled-turkish-bank-logos.png");
  } else if (region.code === "ca") {
    heroImageUrl = modelsUrl("angled-canada-bank-logos.png");
  } else if (region.code === "do") {
    heroImageUrl = atmsUrl("las-terrenas/los-kikis.jpg");
  }
  return (
    <Stack minH={"30vh"} direction={{ base: "column", md: "row" }}>
      <Flex flex={1} align={"center"} justify={"center"} paddingY={4}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
            <Text color={"palette.blue.3"} as={"span"}>
              {tr("hero.title")}{" "}
            </Text>{" "}
          </Heading>
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
            {tr("hero.subtitle")}
          </Text>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Button
              as={RLink}
              to={url("find-an-atm")}
              bg={"palette.orange.bitcoin"}
              color={"black"}
              _hover={{
                bg: "palette.orange.2",
              }}
            >
              {tr("findAnATM")}
            </Button>
            <Button as={RLink} to={url("learn/bitcoin-atm")}>
              {tr("howItWorks")}
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={"Coineka ATMs"} objectFit={"cover"} src={heroImageUrl} />
      </Flex>
    </Stack>
  );
}
