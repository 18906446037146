import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { Button, Box, Container, Heading, Text } from "@chakra-ui/react";
import { useLocale } from "locales";
import { StoreLocator } from "@gocrisp/react-store-locator";
import "./styles.css";

const regionOptions = {
  tr: {
    center: { lat: 41.0185689, lng: 29.0055001 },
    zoom: 12,
  },
  ca: {
    center: { lat: 45.3784337, lng: -74.2384449 },
    zoom: 6,
  },
  do: {
    center: { lat: 19.3216327, lng: -69.5400667 },
    zoom: 16,
  },
};

function terminalToFeature(terminal) {
  const { gpsLat, gpsLon } = terminal.location;
  const geometry = {
    type: "Point",
    coordinates: [parseFloat(gpsLon), parseFloat(gpsLat)],
  };
  const properties = terminal;
  return {
    type: "Feature",
    properties,
    geometry,
  };
}

function apiDataToGeoJson(data) {
  return {
    type: "FeatureCollection",
    features: data.map(({ isOnline, terminal, ...rest }) => {
      return terminalToFeature({ ...terminal, isOnline, ...rest });
    }),
  };
}

const CAS_API_URL = "https://cas-api.coineka.com";
async function fetchGeoJSON(regionCode) {
  const response = await fetch(`${CAS_API_URL}/terminals`);
  const data = await response.json();
  return apiDataToGeoJson(
    data.filter(({ terminal }) => {
      return terminal.location.countryIso2.toLowerCase() === regionCode;
    })
  );
}

async function loadGeoJsonData(region, setGeoJsonData) {
  try {
    const data = await fetchGeoJSON(region.code);
    setGeoJsonData(data);
  } catch (err) {
    setGeoJsonData({ error: err });
  }
}

export default function FindAnATM() {
  const { tr, region, lang } = useLocale();
  const [geoJsonData, setGeoJsonData] = useState(false);
  function t(key) {
    return tr(`pages.findAnATM.${key}`);
  }

  useEffect(() => {
    loadGeoJsonData(region, setGeoJsonData);
  }, [region]);

  if (!geoJsonData) return null;

  const googleMapsApiKey = "AIzaSyBwNYPJmTunOIxQ0w7gsvzgeLTh73wmiE8";

  function renderAtmLocator() {
    const options = regionOptions[region.code];
    if (!options) {
      return <Text>{tr("noATMsOperational")}</Text>;
    }
    if (geoJsonData.error) {
      return (
        <Text color="red">
          Failed to load ATM list ({geoJsonData.error.message}){" "}
          <Button onClick={() => loadGeoJsonData(region, setGeoJsonData)}>
            Retry
          </Button>
        </Text>
      );
    }

    const panelTemplate = `<h2 id="store-list-header">Nearby Locations</h2>
      <button type="button" id="map_close-store-list-button" class="close-button">
        <img alt="Close Store List" src="https://www.google.com/intl/en_us/mapfiles/close.gif" />
      </button>
      <ul id="map_store-list"></ul>
      <div id="map_store-list-message"></div>
    `;
    const storeTemplate = ({ store }) => {
      const location = store.feature.getProperty("location");

      const { name, description } = location;
      const geometry = store.feature.getGeometry().get();

      return `
        <li>
          <button
            data-lat="${geometry.lat()}"
            data-lng="${geometry.lng()}"
            title="${name}"
          >
            <p class="map_banner">${name}</p>
            <p class="map_distance">${store.distanceText}</p>
            <p class="map_address">${description}</p>
          </button>
        </li>
      `;
    };
    const infoWindowTemplate = ({ feature, ...rest }) => {
      // const position = feature.getGeometry().get();
      let offlineWarning = "";
      let inactiveWarning = "";
      if (!feature.getProperty("isOnline")) {
        offlineWarning = `<p style="font-weight:bold;color:red">${t(
          "terminalIsOffline"
        )}</p>`;
      }
      if (!feature.getProperty("active")) {
        inactiveWarning = `<p style="font-weight:bold;color:orange">${t(
          "terminalIsInactive"
        )}</p>`;
      }
      const geometry = feature.getGeometry();
      const query = `${geometry.get().toUrlValue()}`;
      //const query = feature.coordinates.join(",");
      const location = feature.getProperty("location");

      const openingHoursList = feature
        .getProperty("openingHours")
        .map((openingHours) => {
          const is24h = openingHours.from === openingHours.to;
          return { is24h, ...openingHours };
        });
      const isAlwaysOpened = openingHoursList.every(
        (openingHours) => openingHours.is24h
      );
      function getTimeString(dateString) {
        const d = new Date(dateString);
        return d.toLocaleString(lang, { hour: "numeric", hour12: true });
      }
      function renderOpeningHour(openingHour) {
        const day = openingHour.day;
        const from = getTimeString(openingHour.from);
        const to = getTimeString(openingHour.to);

        let hours = `${from} - ${to}`;
        return `<li><span class="day">${t(
          `days.${day}`
        )}</span> <span class="hours">${hours}</span></li>`;
      }
      let openingHoursHtml;
      if (isAlwaysOpened) {
        openingHoursHtml = `<p><strong>${t("isAlwaysOpened")}</strong></p>`;
      } else {
        openingHoursHtml = `<div class="opening-hours">
          <p><strong>${t("openingHours")}:</strong></p>
          <ul>
            ${openingHoursList.map(renderOpeningHour).join("")}
          </ul>
        </div>`;
      }
      return `<div class="map_infowindow_content">
        <div class="map_info">
          <h2>${location.name}</h2>
          <p class="description">${location.description}</p>
          ${offlineWarning}
          ${inactiveWarning}
            <a class="map_btnlink" href="https://www.google.com/maps/search/?api=1&query=${query}" target="_blank">${t(
        "openInGoogleMaps"
      )}</a>
          ${openingHoursHtml}

        </div>
      </div>`;
    };
    const infoWindowOptions = {
      template: infoWindowTemplate,
    };

    const storeListOptions = {
      filterFn: (_, i) => i < 12,
      unitSystem: "metric",
      panelTemplate,
      storeTemplate,
    };
    const searchBoxOptions = {
      autocompleteOptions: {
        componentRestrictions: { country: region.code },
      },
      template: `<div class="map_search-box-card">
        <label for="map_search-box">
          Find nearest Bitcoin ATM
        </label>
        <div class="map_search-box-input-container">
          <input id="map_search-box" type="text" placeholder="Enter an address" />
        </div>
      </div>
      `,
    };

    const onMapInit = (opts) => {
      // console.log({ opts });
      const { map } = opts;
      map.data.setStyle((feature) => ({
        icon: {
          url: `//coineka.com/images/map-marker.svg`,
          /*eslint-disable*/
          scaledSize: new google.maps.Size(80, 80),
        },
        // icon: `https://coineka.com/images/map-marker.svg`,
      }));
    };

    console.log({ geoJsonData });
    const allOptions = {
      geoJson: geoJsonData,
      loaderOptions: { apiKey: googleMapsApiKey },
      infoWindowOptions,
      storeListOptions,
      searchBoxOptions,
      onMapInit,
      mapOptions: {
        ...options,
        disableDefaultUI: true,
        zoomControl: true,
        mapId: "b63835dc7c95ef25",
      },
    };

    return <StoreLocator {...allOptions} />;
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Heading size="3xl" color="palette.blue.1" mb={8}>
            {tr("findAnATM")}
          </Heading>
          {renderAtmLocator()}
        </Box>
      </Container>
    </Layout>
  );
}
