import React from "react";
import Layout from "../../Layout";
import { Box, Container } from "@chakra-ui/react";

export default function Learn() {
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 4 }}>Learning section is coming soon.</Box>
      </Container>
    </Layout>
  );
}
