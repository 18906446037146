import React from "react";
import { Image } from "@chakra-ui/react";
import turkeySVG from "flag-icons/flags/4x3/tr.svg";
import canadaSVG from "flag-icons/flags/4x3/ca.svg";
import domrepSVG from "flag-icons/flags/4x3/do.svg";

const code2image = {
  tr: turkeySVG,
  ca: canadaSVG,
  do: domrepSVG,
};

export default function Flag({ code, ...rest }) {
  const image = code2image[code];
  if (image) {
    return <Image src={image} alt={`${code} flag`} {...rest} />;
  }
  return <></>;
}
