import React, { useContext, useEffect } from "react";
import { urlToLocale, DEFAULT_LOCALE, UNKNOWN } from "./";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation, I18nextProvider } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "./i18n.js";
const LocaleContext = React.createContext(DEFAULT_LOCALE);

function getBaseUrl(region, lang) {
  return `https://${region.baseUrl}/${lang}/`;
}

export function useLocale() {
  /* eslint-disable */
  const navigate = useNavigate();
  const locale = useContext(LocaleContext);
  const { region } = locale;
  const namespace = region.code;
  const { t, i18n } = useTranslation(namespace);

  // translate function
  // like t but autoprefixes the key with the current region's code
  // IDEA: fallback to global if no region specific translation found?
  /*
  const tr = (key, ...args) => {
    // const txt = t(`regions.${region.code}.${key}`, ...args);
    const txt = t(key, ...args);
    return txt;
  };
  */
  const tr = t;
  const trExists = (key, opts) => {
    return i18n.exists(key, { ns: namespace, ...opts });
  };

  const baseURL = new URL(getBaseUrl(region, locale.lang));

  // url relative to the locale URL
  const url = (input) => {
    return new URL(input, baseURL.toString()).pathname;
  };

  const switchLang = (newLang) => {
    if (newLang === locale.lang) return;
    // TODO: keep the active page instead of going to home page
    const newPath = new URL(getBaseUrl(region, newLang)).pathname;
    return navigate(newPath);
  };

  return { t, tr, trExists, i18n, baseURL, url, switchLang, ...locale };
}

function redirect(url) {
  console.log(`redirecting to ${url}`);
  window.location.replace(url);
}

function guessLang(langs) {
  const browserLang = navigator.language || navigator.userLanguage;

  // try with the exact string (e.g. en-US)
  if (langs.includes(browserLang)) return browserLang;

  // try with first part (e.g. en)
  const code = browserLang.split("-")[0];
  if (langs.includes(code)) return code;

  return langs[0];
}

function urlPathToLang(path, region) {
  const basePath = new URL(`https://${region.baseUrl}`).pathname;
  // strip base path
  const relPath = path.substring(basePath.length);
  const lang = relPath.split("/").filter((e) => !!e)[0];
  return lang;
}

export default function LocaleProvider({ children }) {
  const location = useLocation();
  const url = window.location.href;
  // TODO: rename to urlToRegion?
  const { region, lang: guessedLang } = urlToLocale(url);

  // if could not detect lang from path, we need to guess best language and
  // redirect there.
  if (guessedLang === UNKNOWN) {
    // guess lang and redirect
    const lang = guessLang(region.langs);

    // append lang after baseUrl
    const { baseUrl } = region;
    let pos = url.search(baseUrl);
    // unknown url...
    if (pos < 0) {
      redirect(`//${baseUrl}/${lang}`);
      return;
    }
    const before = url.substring(0, pos + baseUrl.length);
    const after = url.substring(pos + baseUrl.length);
    const newUrl = `${before}/${lang}${after}`;
    redirect(newUrl);
    return;
    // redirect...
  }

  const lang = urlPathToLang(location.pathname, region);
  const locale = { region, lang };
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <LocaleContext.Provider value={locale}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </LocaleContext.Provider>
  );
}
