import { useLocale } from "locales";
import React from "react";
import { Routes, Route } from "react-router-dom";

// import ComingSoon from "components/ComingSoon";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import HostAnATM from "./pages/HostAnATM";
import FindAnATM from "./pages/FindAnATM";
import Learn from "./pages/Learn";
import LearnBitcoin from "./pages/Learn/LearnBitcoin";
import LearnBitcoinATM from "./pages/Learn/LearnBitcoinATM";
import PressKit from "./pages/PressKit";
import Support from "./pages/Support";

export default function CommonRoutes() {
  const locale = useLocale();
  const basePath = locale.baseURL.pathname;
  return (
    <Routes>
      <Route path={basePath}>
        <Route path="" element={<Home />} />
        <Route path="learn">
          <Route path="" element={<Learn />} />
          <Route path="bitcoin" element={<LearnBitcoin />} />
          <Route path="bitcoin-atm" element={<LearnBitcoinATM />} />
        </Route>
        <Route path="host-an-atm" element={<HostAnATM />} />
        <Route path="find-an-atm" element={<FindAnATM />} />
        <Route path="press-kit" element={<PressKit />} />
        <Route path="support" element={<Support />} />
        <Route path="*" element={<Error404 />} />
      </Route>
      {/* Unknown locale... */}
      <Route path="*" element={<Error404 unknownLocale />} />
    </Routes>
  );
}
