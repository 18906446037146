import React from "react";
import { Heading, Text, OrderedList, ListItem, Link } from "@chakra-ui/react";
import { useLocale } from "locales";

export default function LearnBitcoinEN() {
  const { tr } = useLocale();
  return (
    <>
      <Heading size="3xl" color="palette.blue.1" mb={6}>
        Bitcoin ATMs: A beginner’s guide to Bitcoin teller machines
      </Heading>

      <Heading size="xl" color="palette.blue.1">
        What is a Bitcoin ATM?
      </Heading>

      <Text>
        An ATM or BTM is similar to a regular automated teller machine in that
        it is a physical kiosk. What differentiates it from typical ATMs, of
        course, is that it allows users to buy Bitcoin in exchange for cash. Our
        BTMs also allow users to sell Bitcoin for cash.
      </Text>
      <Text>
        BTMs look like typical ATMs. However, they connect to a Bitcoin wallet
        instead of a bank account. In place of a bank card, the Bitcoin wallet
        serves as a repository from which coins are sent or debited.
      </Text>

      <Heading size="xl" color="palette.blue.1">
        How to use a Bitcoin ATM
      </Heading>
      <Text>
        Below are the typical steps for buying Bitcoin or other cryptocurrency
        with cash using a Bitcoin ATM.
      </Text>
      <Text>
        <OrderedList fontWeight="bold">
          <ListItem>Verify your identity</ListItem>
          <ListItem>Scan your wallet QR code</ListItem>
          <ListItem>Insert cash in machine</ListItem>
          <ListItem>Confirm the amount and complete your transaction</ListItem>
        </OrderedList>
      </Text>
      <Text>
        If you have any questions about the process, don't hesitate to{" "}
        <Link
          href={`mailto:${tr("support.email")}`}
          isExternal
          color="palette.link"
        >
          contact support
        </Link>
        .
      </Text>
    </>
  );
}
