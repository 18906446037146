import React from "react";
import { useLocale } from "../../locales";
import BackgroundImage from "../BackgroundImage";
import {
  Button,
  Center,
  Text,
  Flex,
  Box,
  Container,
  Stack,
  DarkMode,
} from "@chakra-ui/react";
import Logo from "../../components/Logo";
import bgBtc from "../../assets/btc-bg.jpg";

function Card({ children }) {
  return (
    <Box
      padding={4}
      boxShadow="base"
      borderRadius="md"
      bgColor="rgba(0,0,0,0.7)"
    >
      {children}
    </Box>
  );
}

function Header() {
  const {
    tr,
    region: { baseUrl },
  } = useLocale();
  return (
    <Box
      as="a"
      href={`//${baseUrl}`}
      w="100%"
      maxW="400px"
      mb="12"
      align="center"
    >
      <Logo variant="light" />
      <Box fontWeight="bold" opacity="0.75">
        {tr("shortTagLine")}
      </Box>
    </Box>
  );
}

function Body() {
  const { tr } = useLocale();
  const supportLink = `mailto:${tr("support.email")}`;
  return (
    <Card>
      <Stack spacing={3} textAlign="center">
        <Text>{tr("comingSoonText")}</Text>
        <Text align="center">{tr("comeBackText")}</Text>
        <Text>
          <Center>
            <Button as="a" href={supportLink} colorScheme="orange">
              {tr("contactSupport")}
            </Button>
          </Center>
        </Text>
      </Stack>
    </Card>
  );
}

function Footer() {
  const { tr } = useLocale();
  return (
    <Card>
      <Center>
        <Text fontSize="xs">{tr("footerNotice")}</Text>
      </Center>
    </Card>
  );
}

export default function ComingSoon() {
  // space between logo and country list
  const my = 12;

  return (
    <DarkMode>
      <Box bgColor="#000" color="whiteAlpha.900">
        <BackgroundImage.Wrap>
          <BackgroundImage.Image
            bottom="0"
            src={bgBtc}
            alt="background"
            minW="800px"
            opacity={0.9}
          />
          <BackgroundImage.Content>
            <Container maxW="container.md">
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                minH={`calc(100vh - ${my * 4 * 2}px)`}
                my={my}
              >
                <Header />
                <Stack spacing={8} w="100%">
                  <Body />
                  <Footer />
                </Stack>
              </Flex>
            </Container>
          </BackgroundImage.Content>
        </BackgroundImage.Wrap>
      </Box>
    </DarkMode>
  );
}
