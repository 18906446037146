import React from "react";
import Layout from "../Layout";
import {
  Link,
  Box,
  Container,
  Heading,
  Text,
  Image,
  Button,
  VStack,
} from "@chakra-ui/react";
import { useLocale } from "../../../locales";

export default function PressKit() {
  const { tr } = useLocale();
  const publicUrl = (input) => `${process.env.PUBLIC_URL}/${input}`;

  const logoSvgUrl = publicUrl("images/logos/coineka-logo.svg");

  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Heading size="3xl" color="palette.blue.1" mb={8}>
            {tr("pressKit")}
          </Heading>
          <VStack align="center">
            <Box py={8}>
              <Link href={logoSvgUrl} download>
                <Image
                  src={logoSvgUrl}
                  alt="Coineka Logo"
                  w="100%"
                  maxW={400}
                />
              </Link>
            </Box>
            <VStack p={5} shadow="md" borderWidth="1px" maxW={600}>
              <Text mb={4} textAlign="center">
                {tr("downloadPressKitDescription")}
              </Text>

              <Box>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  as="a"
                  href="/downloads/presskit.zip"
                >
                  {tr("downloadPressKit")}
                </Button>
              </Box>
            </VStack>
          </VStack>
        </Box>
      </Container>
    </Layout>
  );
}
