import React from "react";
import { Heading, Text, OrderedList, ListItem, Link } from "@chakra-ui/react";
import { useLocale } from "locales";

export default function LearnBitcoinEN() {
  const { tr } = useLocale();
  return (
    <>
      <Heading size="3xl" color="palette.blue.1" mb={6}>
        Bitcoin ATM'leri: Bitcoin vezne makinelerine yeni başlayanlar için
        kılavuz
      </Heading>

      <Heading size="xl" color="palette.blue.1">
        Bitcoin ATM'si nedir?
      </Heading>

      <Text>
        Bir ATM veya BTM, bu yönüyle normal bir otomatik vezne makinesine
        benzer. fiziksel bir kiosktur. Tipik ATM'lerden farkı nedir? Tabii ki,
        kullanıcıların nakit karşılığında Bitcoin satın almalarına izin
        vermesidir. Bizim BTM'ler ayrıca kullanıcıların Bitcoin'i nakit olarak
        satmasına izin verir.
      </Text>
      <Text>
        BTM'ler tipik ATM'lere benziyor. Ancak, bir Bitcoin cüzdanına
        bağlanırlar banka hesabı yerine Banka kartı yerine Bitcoin cüzdanı
        madeni paraların gönderildiği veya borçlandırıldığı bir depo görevi
        görür.
      </Text>

      <Heading size="xl" color="palette.blue.1">
        Bitcoin ATM'si nasıl kullanılır?
      </Heading>
      <Text>
        Bitcoin veya diğer kripto para birimini satın almak için tipik adımlar
        aşağıdadır. bir Bitcoin ATM kullanarak nakit ile.
      </Text>
      <Text>
        <OrderedList fontWeight="bold">
          <ListItem>Kimliğinizi doğrulayın</ListItem>
          <ListItem>Cüzdanınızın QR kodunu tarayın</ListItem>
          <ListItem>Makineye nakit girin</ListItem>
          <ListItem>Tutarı onaylayın ve işleminizi tamamlayın</ListItem>
        </OrderedList>
      </Text>
      <Text>
        Süreçle ilgili herhangi bir sorunuz varsa, destek ile iletişime{" "}
        <Link
          href={`mailto:${tr("support.email")}`}
          isExternal
          color="palette.link"
        >
          geçmekten çekinmeyin
        </Link>
        .
      </Text>
    </>
  );
}
