import React from "react";
import Layout from "../../Layout";
import { Box, Container, Stack } from "@chakra-ui/react";
import { useLocale } from "locales";
import ContentEN from "./LearnBitcoinATM.en.js";
import ContentTR from "./LearnBitcoinATM.tr.js";

const contents = {
  en: ContentEN,
  tr: ContentTR,
};

//TODO: taken from cointelegraph... re-write with original content
export default function LearnBitcoin() {
  const { lang } = useLocale();
  let Content = contents[lang] || ContentEN;
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }} maxW="sizes.maxLineWidth">
          <Stack spacing={3}>
            <Content />
          </Stack>
        </Box>
      </Container>
    </Layout>
  );
}
