import React from "react";
import Layout from "../../Layout";
import { Container } from "@chakra-ui/react";
import HeroSection from "./HeroSection";

export default function Home() {
  return (
    <Layout>
      <Container maxW="container.xl">
        <HeroSection />
      </Container>
    </Layout>
  );
}
