// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";

import { mode } from "@chakra-ui/theme-tools";

// 2. Extend the theme to include custom colors, fonts, etc
// const mainOrange = "#ff9900";
const mainOrange = "#F7931A";
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
  palette: {
    link: "#4081e3",
    orange: {
      1: mainOrange,
      bitcoin: mainOrange,
      2: "#fd7702",
      3: "#ff5003",
    },
    blue: {
      1: "#03102E",
      2: "#132542",
      3: "#003f7d",
    },
  },
};

const sizes = {
  sizes: {
    maxLineWidth: "1024px",
  },
};

const global = (props) => ({
  body: {
    bg: mode("white", "palette.blue.1")(props),
  },
});

const config = {
  initialColorMode: "light",
};

const theme = extendTheme({ styles: { global }, colors, sizes, config });

export default theme;
