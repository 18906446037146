import React from "react";
import Layout from "../Layout";
import { Box, Container, Heading } from "@chakra-ui/react";
import { useLocale } from "../../../locales";

export default function Error404({ unknownLocale }) {
  const { tr } = useLocale();
  function renderAdditionalInfo() {
    if (unknownLocale) {
      return <>(unknown locale)</>;
    }
  }
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Heading size="3xl" color="palette.blue.1" mb={8}>
            {tr("error404")} {renderAdditionalInfo()}
          </Heading>
        </Box>
      </Container>
    </Layout>
  );
}
