import React from "react";
import { GLOBAL, TURKEY, DOMREP, CANADA } from "../locales";
import { useLocale } from "../locales/LocaleProvider";
import Global from "./global";
import ComingSoon from "../components/ComingSoon";
import Turkey from "./tr";
import Canada from "./ca";
import DomRep from "./do";

// Switch site depending on locale
function Site() {
  const { region } = useLocale();

  if (region === GLOBAL) {
    return <Global />;
  } else if (region === TURKEY) {
    return <Turkey />;
  } else if (region === CANADA) {
    return <Canada />;
  } else if (region === DOMREP) {
    return <DomRep />;
  }
  return <ComingSoon />;
}

export default Site;
