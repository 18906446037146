import React from "react";
import { useLocale, REGIONS, GLOBAL } from "../../locales";
import BackgroundImage from "../../components/BackgroundImage";
import { HStack, Flex, Box, Container, Heading, Stack } from "@chakra-ui/react";
import Logo from "../../components/Logo";
import Flag from "../../components/Flag";
import bgBtc from "../../assets/btc-bg.jpg";

function Region({ code, baseUrl }) {
  const { t } = useLocale();
  const href = `//${baseUrl}`;
  const countryName = t([`countryName`, code.toUpperCase()].join(""));
  return (
    <Box
      href={href}
      as="a"
      boxShadow="base"
      borderRadius="md"
      bgColor="rgba(0,0,0,0.7)"
      _hover={{ bgColor: "rgba(0,0,0,1)" }}
      role="group"
      color="white"
    >
      <HStack p={5} spacing={5}>
        <Box width="80px">
          <Flag
            code={code}
            opacity="0.85"
            _groupHover={{ opacity: 1, pointer: "cursor" }}
          />
        </Box>
        <Heading fontSize="1.3em">{countryName}</Heading>
      </HStack>
    </Box>
  );
}

function Regions() {
  return (
    <Stack spacing={8}>
      {REGIONS.filter((r) => r !== GLOBAL).map((r) => (
        <Region key={r.code} {...r} />
      ))}
    </Stack>
  );
}

export default function GlobalSite() {
  const { t } = useLocale();

  // space between logo and country list
  const my = 12;

  return (
    <Box bgColor="#000">
      <BackgroundImage.Wrap>
        <BackgroundImage.Image
          bottom="0"
          src={bgBtc}
          alt="background"
          minW="800px"
          opacity={0.9}
        />
        <BackgroundImage.Content>
          <Container maxW="container.md">
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              minH={`calc(100vh - ${my * 4 * 2}px)`}
              my={my}
            >
              <Box w="100%" maxW="400px" mb="12" align="center">
                <Logo variant="light" />
                <Box fontWeight="bold" opacity="0.75" color="white">
                  {t("shortTagLine")}
                </Box>
              </Box>
              <Box w="100%" maxW="370px">
                <Regions />
              </Box>
            </Flex>
          </Container>
        </BackgroundImage.Content>
      </BackgroundImage.Wrap>
    </Box>
  );
}
