import React from "react";
import Layout from "../../Layout";
import { Box, Container, Heading, AspectRatio } from "@chakra-ui/react";
import { useLocale } from "locales";

export default function LearnBitcoin() {
  const { tr } = useLocale();
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Heading
            size="3xl"
            color="palette.blue.1"
            mb={8}
            maxW="sizes.maxLineWidth"
          >
            {tr("pages.learn.bitcoin.title")}
          </Heading>

          <Box>
            <AspectRatio maxW="560px" ratio={1}>
              <iframe
                title="What is Bitcoin?"
                src={tr("pages.learn.bitcoin.youtubeUrl")}
                allowFullScreen
              />
            </AspectRatio>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
