import { REGIONS, UNKNOWN, DEFAULT_REGION } from "./constants";

export function urlToRegion(url) {
  // we match the URL against all baseUrls
  // the baseUrl that is closest to string's beginning wins, e.g.:
  // http://coineka.com/blog/announing-coineka.ca matches coineka.com and not coineka.ca
  const matchingRegions = REGIONS.map((region) => {
    const pos = url.indexOf(region.baseUrl);
    return { pos, region };
  }).filter(({ pos }) => pos >= 0);
  if (!matchingRegions.length) {
    return DEFAULT_REGION;
  }
  const sortedRegions = [...matchingRegions].sort((r1, r2) => {
    if (r1.pos > r2.pos) {
      return 1;
    } else if (r1.pos < r2.pos) {
      return -1;
    } else {
      const l1 = r1.region.baseUrl.length;
      const l2 = r2.region.baseUrl.length;
      return l2 - l1;
    }
  });

  // console.dir({ url, sortedRegions }, { depth: null });

  const bestMatch = sortedRegions[0];

  return bestMatch.region;
}

export function afterBaseUrl(url, baseUrl) {
  const pos = url.indexOf(baseUrl);
  return url.substring(pos + baseUrl.length);
}

// FIXME: redirect bug when URL ends with "#"
export function urlToLocale(url) {
  const region = urlToRegion(url);
  let lang = UNKNOWN;
  const pathSegments = afterBaseUrl(url, region.baseUrl)
    .split("/")
    .filter((s) => s.length);
  const firstPathSegment = pathSegments[0];
  if (region.langs.includes(firstPathSegment)) {
    lang = firstPathSegment;
  }
  return { region, lang };
}
