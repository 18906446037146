import { Box, Image as ChakraImage } from "@chakra-ui/react";

function Wrap({ children }) {
  return (
    <Box overflow="hidden" position="relative">
      {children}
    </Box>
  );
}

function Image({ ...rest }) {
  const props = { ...rest };
  if (!Object.keys(props).includes("bottom")) {
    props.top = 0;
  }
  if (!Object.keys(props).includes("right")) {
    props.left = 0;
  }
  return <ChakraImage position="absolute" {...props} />;
}

function Content({ children }) {
  return (
    <Box position="relative" overflow="auto">
      {children}
    </Box>
  );
}

const BackgroundImage = {
  Wrap,
  Image,
  Content,
};

export default BackgroundImage;
